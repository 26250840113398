var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"genetic-data-list"},[_c('ui-debio-error-dialog',{attrs:{"show":!!_vm.error,"title":_vm.error ? _vm.error.title : '',"message":_vm.error ? _vm.error.message : ''},on:{"close":function($event){_vm.error = null}}}),_c('ui-debio-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loadingData},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-data-list__title"},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-data-list__description"},[_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"item.uploadDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-data-list__upload"},[_c('span',[_vm._v(_vm._s(item.uploadAt))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"genetic-data-list__actions"},[_c('ui-debio-icon',{attrs:{"icon":_vm.pencilIcon,"size":"16","role":"button","stroke":""},on:{"click":function($event){return _vm.onEdit(item)}}}),_c('ui-debio-icon',{attrs:{"icon":_vm.trashIcon,"size":"16","role":"button","stroke":""},on:{"click":function($event){return _vm.onDelete(item)}}})],1)]}}],null,true)}),_c('ConfirmationDialog',{attrs:{"show":_vm.showDialog,"txWeight":Number(_vm.txWeight).toFixed(4),"title":"Delete Genetic Data","message":"By delete this Genetic Data, you might not be able to retrieve it back","btnMessage":"Delete","loading":_vm.isDeleting},on:{"close":function($event){_vm.showDialog=false},"click":_vm.confirmedDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }