//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { geneticIllustration, plusCircle } from "@debionetwork/ui-icons";
import GeneticDataList from "./GeneticDataList.vue";
import GeneticAnalysisList from "./GeneticAnalysisList.vue";
export default {
  name: "GeneticData",
  components: {
    GeneticDataList,
    GeneticAnalysisList
  },
  data: () => ({
    geneticIllustration,
    plusCircle,
    tabs: null,
    cardBlock: false
  })
};