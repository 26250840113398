import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import { queryGeneticDataByOwnerId, removeGeneticData, removeGeneticDataFee } from "@debionetwork/polkadot-provider";
import { errorHandler } from "@/common/lib/error-handler";
import ConfirmationDialog from "@/common/components/Dialog/ConfirmationDialog";
export default {
  name: "GeneticDataList",
  components: {
    ConfirmationDialog
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      web3: state => state.metamask.web3
    })
  },
  data: () => ({
    pencilIcon,
    trashIcon,
    headers: [{
      text: "Title",
      value: "title",
      sortable: true
    }, {
      text: "Description",
      value: "description",
      sortable: false
    }, {
      text: "Uploaded",
      value: "uploadDate",
      sortable: true
    }, {
      text: "Action",
      value: "actions",
      sortable: false
    }],
    items: [],
    showDialog: false,
    isDeleting: false,
    selectedDataId: null,
    txWeight: 0,
    error: null,
    loadingData: false
  }),
  watch: {
    lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (dataEvent[1] === this.wallet.address) {
          if (e.method === "GeneticDataRemoved") {
            this.fetchGeneticData();
            this.isDeleting = false;
            this.showDialog = false;
          }
        }
      }
    }
  },
  async mounted() {
    this.fetchGeneticData();
    this.getRemoveDataFee();
  },
  methods: {
    async fetchGeneticData() {
      try {
        this.loadingData = true;
        this.items = [];
        const accountId = this.wallet.address;
        const dataList = await queryGeneticDataByOwnerId(this.api, accountId);
        for (const {
          id,
          owenerId,
          reportLink,
          title,
          description,
          createdAt,
          updatedAt
        } of dataList) {
          let uploadAt;
          if (updatedAt !== "0") {
            uploadAt = this.formatDate(updatedAt);
          } else {
            uploadAt = this.formatDate(createdAt);
          }
          const item = {
            id,
            owenerId,
            reportLink,
            title,
            description,
            uploadAt
          };
          this.items.push(item);
        }
        this.items.sort((a, b) => {
          if (new Date(a.uploadAt) < new Date(b.uploadAt)) {
            return 1;
          }
          if (new Date(a.uploadAt) > new Date(b.uploadAt)) {
            return -1;
          }
          return 0;
        });
        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
      }
    },
    onEdit(item) {
      this.$router.push({
        name: "customer-add-genetic-data",
        params: {
          id: item.id
        }
      });
    },
    onDelete(item) {
      this.selectedDataId = item.id;
      this.showDialog = true;
    },
    async getRemoveDataFee() {
      const txWeight = await removeGeneticDataFee(this.api, this.wallet, this.selectedDataId);
      this.txWeight = this.web3.utils.fromWei(String(txWeight.partialFee), "ether");
    },
    async confirmedDelete() {
      this.isDeleting = true;
      try {
        await removeGeneticData(this.api, this.wallet, this.selectedDataId);
      } catch (e) {
        const error = await errorHandler(e.message);
        this.error = error;
        this.showDialog = false;
        this.isDeleting = false;
      }
    },
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    }
  }
};