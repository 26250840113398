var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"genetic-analysis-list"},[_c('ui-debio-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":['timestamp'],"sort-desc":[true],"loading":_vm.loadingData},scopedSlots:_vm._u([{key:"item.serviceName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-analysis-list__service"},[_c('span',[_vm._v(_vm._s(item.serviceName))])])]}},{key:"item.analystName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-analysis-list__name"},[_c('span',[_vm._v(_vm._s(item.analystName))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-analysis-list__createdDate"},[_c('span',[_vm._v(_vm._s(item.createdAt))])])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-analysis-list__updatedDate"},[_c('span',[_vm._v(_vm._s(item.updatedAt))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column genetic-analysis-list__status"},[_c('span',[_vm._v(_vm._s(item.status))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"genetic-analysis-list__actions"},[_c('ui-debio-icon',{attrs:{"icon":_vm.eyeIcon,"size":"16","role":"button","stroke":""},on:{"click":function($event){return _vm.toDetail(item)}}}),_c('ui-debio-icon',{directives:[{name:"show",rawName:"v-show",value:(item.status === 'Done'),expression:"item.status === 'Done'"}],attrs:{"icon":_vm.downloadIcon,"size":"16","role":"button","stroke":""},on:{"click":function($event){return _vm.toDownload(item)}}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }